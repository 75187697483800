@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");
.main-repo-body {
  background: var(
    --Gradient-2,
    linear-gradient(180deg, #171717 -12.86%, #3e4148 -12.85%, #1b1c1e 104.45%)
  );
  width: 100%;
  height: 86vh;
  display: flex;
  flex-direction: column;
  border-radius: 10px 0 0 10px;
}

.analytics_vin {
  /* position: absolute;
  left: 13%;
  right: 55%;
  top: 9%; */
  height: 5%;
  width: 93.5%;
  margin-top: 0.5rem;
  background: rgb(25 25 25);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  /* align-items: center; */
  gap: 0.5rem;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 10px;
  margin-left: 2rem;
  /* padding-left: 2rem; */
  /* padding-right: 2rem; */
  font-family: "Montserrat", sans-serif;
  margin-bottom: 1rem;
}
.analytics-data-one {
  position: absolute;
  left: 11%;
  right: 60.42%;
  top: 55%;
  /* height: 37%; */
  background: rgba(0, 0, 0, 0.22);
  border-radius: 15px;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.analytics-data-two {
  /* position: absolute;
  left: 0;
  top: 0; */
  width: 96%;
  /* height: 33%; */
  display: flex;
  justify-content: center;

  background: rgb(25 25 25);
  border-radius: 15px;
}

.analytics-data-three {
  /* position: absolute;
  left: 0;
  top: 33%; */
  width: 96%;
  /* height: 33%; */
  display: flex;
  justify-content: center;

  background: rgb(25 25 25);
  border-radius: 15px;
}
.analytics-data-four {
  /* position: absolute;
  left: 0;
  top: 66%; */
  width: 96%;
  /* height: 30%; */
  display: flex;
  justify-content: center;

  background: rgb(25 25 25);
  border-radius: 15px;
}

.analytics_open-vehicle-number {
  display: flex;
  align-items: center;
  width: 82%;
  height: 2.5rem;
  background: #1b3643;
  border-radius: 15px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
}

.analytics_vehicle-number {
  display: flex;
  align-items: center;
  width: 82%;
  height: 2.5rem;
  background: #1b3643;
  border-radius: 15px;
  color: white;
  /* font-family: "Lato", sans-serif; */
  font-family: "Montserrat", sans-serif;
  margin-top: 3%;
}

.repo-graphs {
  display: flex;
  /* overflow-y: hidden; */
  flex-direction: column;
  /* position: absolute;
  left: 47%;
  right: 0%;
  top: 9%; */
  height: 85%;
  gap: 0.5rem;
  padding-left: 2rem;
  /* width: 100%; */
}
.idx_vehicle-number {
  width: auto;
  text-align: left;
}
.custom-scrollbars__content {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.custom-scrollbars__content::-webkit-scrollbar {
  display: none;
}

.repo-graphs::-webkit-scrollbar {
  width: 5px;
  opacity: 0.7;
}

.repo-graphs::-webkit-scrollbar-track {
  background: #454545;
  border-radius: 10px;
  opacity: 0.7;
}

.repo-graphs::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #1effed;
  opacity: 0.7;
}
.repo-graphs::-webkit-scrollbar-track:hover {
  opacity: 1;
}

.data {
  color: white;
}

#main-alert-box {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 98vh;
  right: 0;
  background: rgb(0, 0, 0, 0.4);
  z-index: 15;
}

.loader {
  position: absolute;
  display: block;
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #fff;
}
.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader:after {
  color: #44d1c8;
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}
.first-map {
  width: inherit;
}

.repo-heading {
  color: white;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}
.repo {
  display: flex;
  color: white;
  /* gap: 4rem; */
  width: 20rem;
}
.submit_button_div {
  /* position: absolute;
  bottom: 4%; */
}
.submit_button_none {
  display: none;
}
.input-container {
  display: flex;
  gap: 10rem;
  width: inherit;
  align-items: center;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}

@media (max-width: 1079px) {
  .repo-heading {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1285px) and (max-height: 605px) {
  .main-repo-body {
    height: 82.25vh;
  }
  .analytics_vin {
    /* width: 20rem; */
    left: 17%;
    top: 12%;
    align-items: center;
    margin-top: 1.5rem;
    height: 8%;
  }
  .repo-graphs {
    height: 22rem;
    top: 12%;
    overflow-y: scroll;
    padding-top: 1rem;
  }
  .repo {
    gap: 1.5rem;
  }
}
