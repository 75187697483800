.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: transparent
}
.header-title-container {
  display: flex;
  align-items: center;
  margin: 1rem;
  gap: 0.75rem;
  margin-left: 2.5rem;
}
.menu-container {
  padding: 0.5rem 1rem;
  padding-right: 2rem;
  display: flex;
  gap: 1rem;
}

.dashboard-header .dropdown {
  background: none;
  /* margin-right: 2rem; */
  margin-right: 1rem;
}
/* .dashboard-header .dropdown img {
  width: 17.336px;
  height: 20.625px;
} */
.dashboard-header .dropdown img {
  width: 2rem;
  height: 2rem;
}
.dashboard-header .dropdown-toggle {
  background: none;
  display: flex;
  padding-left: 0;
  padding-right: 1.75rem;
}
.dashboard-header .dropdown-menu {
  position: absolute;
  /* background-color: black; */
  border-radius: 10px;
  padding: 0.5rem;
  right: 1rem;
  width: 8rem;
  top: 2.9rem;
   /* background-color: #007bff; */
  background: var(--Gradient1, linear-gradient(180deg, #212121 0%, #101010 100%));
}
.dashboard-header .dropdown-menu :hover {
  background: var(
    --Grey-Gradient,
    linear-gradient(180deg, #666 -12.86%, #5c5c5c -12.85%, #404040 104.45%)
  ); /* Blue background color */
  color: #ffffff;
  width: 8rem;
  padding: 0.5rem;
  border-radius: 10px;
 top: 0rem;
 right: 0rem;
   position: absolute;
}
.dashboard-header .dropdown-menu li {
  padding-left: 1rem;
}
.dashboard-header .dropdown-menu li:hover {
  background-color: #444;
  border-radius: 10px;
}
.dashboard-header .dropdown-menu li.active {
  padding-left: 1rem;
  width: 4.5rem;
  font-size: 14px;
  padding-right: 0;
}
.dashboard-header .dropdown-menu li.active img {
  height: 13px;
  width: max-content;
  padding-right: 0.5rem;
}
.profile-container {
  display: flex;
  align-items: center;
}
