@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

.main-body {
  background: var(
    --Gradient1,
    linear-gradient(180deg, #212121 0%, #101010 100%)
  );
  width: 100%;
  height: 100vh;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  margin-right: 4rem;
  font-family: Montserrat, sans-serif;
}
.header-img {
  background: transparent;
  background-color: ora;
}
.header-title {
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px;
}
/*Login window*/
.login-window-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-window {
  width: 800px;
  height: 387px;
  flex-shrink: 0;
  border-radius: 50px;
  background: linear-gradient(
    179deg,
    #171717 -11.76%,
    #3e4148 -11.74%,
    #1b1c1e 120.91%
  );
  display: flex;
  justify-content: space-evenly;
}
.analytics-logo {
  width: 232px;
  height: 85px;
}
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 3rem;
}
.signup-title {
  width: inherit;
  height: 30.377px;
  color: #fff;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 2.5rem 3rem 0 3rem;
}
.password-container {
  display: flex;
  align-items: center;
  border-radius: 9px;
  background: #101010;
  color: #c5c5c5b3;
  justify-content: space-between;
}
.password-container .password-text{
  border: none;
  width: 274px;
}
.password-container input,
.signup input {
  width:357px;
  height: 1.75rem;
  border-radius: 9px;
  background: #101010;
  color: #fff;
  padding-left: 15px;
  border: 1px solid black;
}
.signup input::placeholder {
  color: rgba(197, 197, 197, 0.7);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* .signup .signup-user{
  height: 0;
  width: 340px;
  padding-right: 25px;
  color:red;
} */
.password-container {
  margin-top: 1rem;
  padding-right: 10px;
  width:95%;
}

.forgot-password {
  width: inherit;
  height: 10px;
  color: #c5c5c5;
  text-align: right;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.4%; /* 13.74px */
  text-decoration-line: underline;
  padding-right: 1rem;
  margin-top: 10px;
  cursor: pointer;
}
/* .password-visibility {
  position: absolute;
  top: 17.7rem;
  right: 27%;
  padding-right: 10px;
} */
.verticle-line {
  margin-left: 30px;
  width: 2px;
  background-color: #1e1e1e;
}
.otp-input-container {
  /* width:300; */
  display: flex;
  gap: 20px; /* Space between OTP fields */
  justify-content: center;
  margin-top: -10px;
  /* padding-bottom: 0; */
}

.otp-input-container .otp-input-box {
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 15px;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  /* background-color: #f0f0f0; */
  color:whitesmoke;
  outline: none;
  padding: 0!important;
  margin-bottom: 0px;
}

.otp-input-box:focus {
  /* border-color: #4e90f1; */
  /* background-color: #fff; */
  box-shadow: 0 0 5px rgba(72, 124, 204, 0.5);
}

.otp-input-box::placeholder {
  color:whitesmoke;
}
#timer p{
  /* padding: 0px; */
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color:lightgray;
  margin-top: 0;
}
#resend-otp-button {
  text-align: right;
  background: transparent;
  /* border: 0px solid rgba(255, 21, 0, 0.15); */
  /* border-radius: 20px; */
  /* margin: 5%; */
  /* margin-left: 2rem; */
  /* width: 5rem;*/
  /* height: 2rem;  */
  /* margin-top:  -10px; */
  white-space: nowrap;
  /* position :relative; */
  /* left:139px;
  bottom:19px; */
  /* padding-left: 90px; */
  color: white;
  font-size: 10px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  /* font-weight: 500; */
}
.send-otp-btn {
  display: inline-flex;
  height: 27px;
  padding: 5px 22px;
  justify-content: center;
  align-items: center;
  /* gap: 100px; */
  flex-shrink: 0;
  border-radius: 6px;
  background: var(
    --Gradient-Blue-1,
    linear-gradient(180deg, #71d0f6 0%, #007bc9 100%)
  );
  margin-top: -15px;
  position: relative;
  bottom: 12px;
  top: -0.5rem;
  border: none;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* margin-top: 1rem; */
  /* margin-left: 4rem; */
}
.send-otp-btn:hover {
  border-radius: 6px;
  border: 1px solid var(--Button-Blue-Gradient, #71d0f6);
  background: transparent;
}
.verifiedAnimation{
  display: inline-flex;
  /* height: 27px; */
  /* padding: 5px 22px; */
  justify-content: center;
  align-items: center;
  /* gap: 100px; */
  flex-shrink: 0;
  border-radius: 6px;
  /* background: var(
    --Gradient-Blue-1,
    linear-gradient(180deg, #71d0f6 0%, #007bc9 100%)
  ); */
  /* margin-top: -10px; */
  position: relative;
  bottom: 10px;
  /* border: none; */
  /* color: #fff; */
  text-align: center;
  /* font-family: Montserrat; */
  /* font-size: 12px;
  font-style: normal;
  font-weight: 500; */
  line-height: normal;
}
.signup-container{
  margin-top: 75px;
  width: 40%;
  padding: 5px 5px 5px 5px;
  /* gap:100px; */
}
.signup-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4rem;
}
.signup-footer p {
  /* width: 48.618px; */
  /* height: 12.965px; */
  color: rgba(255, 255, 255, 0.5);
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: -10px;
}
.signup-user.error {
  /* font-size: 11px; */
  border: 1px solid red;
  /* border-color: red; Change border color to red */
}

.error-message {
  background: transparent;
  text-align:left;
  /* border: 0px solid rgba(255, 21, 0, 0.15); */
  /* border-radius: 20px; */
  /* margin: 5%; */
  /* margin-left: 2rem; */
  /* width: 5rem;*/
  /* height: 2rem;  */
  /* margin-top:  -10px; */
  white-space: nowrap;
  /* position :relative; */
  /* right:1px; */
  /* bottom:15px; */
  /* padding-left: 220px; */
  /* color: white; */
  font-size: 10px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 100;
  /* font-size: 12px; */
  color:rgb(248, 33, 5);
}
.resend-container {
  position:relative;
  bottom:1.2rem;
  display: flex;
  justify-content: space-between;
  margin-left: 1rem;    width: 18rem;
  align-items: center;
  /* align-items: center; Vertically aligns both elements */
  /* gap: 2px; Adds some space between the elements */
  /* padding-left: 25px; */
}




/* products list*/
.products-container {
  /* margin-top: 2rem; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: baseline;
  gap: 4rem;
  margin-bottom: 1.5rem;
}
.product {
  width: min-content;
  height: inherit;
  padding-top: 2rem;
}
.product img {
  opacity: 0.6;
  width: 6rem;
}
.product p {
  color: #fff;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.8px;
  opacity: 0.3;
}
.footer {
  color: #818181;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 1rem 1.25rem;
}

/*forgot password styling*/
.back {
  position: relative;
  cursor: pointer;
}
.back img {
  position: absolute;
  top: -5rem;
  left: 1rem;
  padding: 0.5rem 0rem 0rem 1rem;
}
.forgot-password-title {
  width: 294px;
  height: 19px;
  color: #fff;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 2rem 5rem 1rem 3rem;
}
.forgot-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem 2rem;
}
.forgot-password-window {
  width: 440px;
  height: 192px;
  flex-shrink: 0;
  border-radius: 35px;
  background: linear-gradient(
    179deg,
    #171717 -11.76%,
    #3e4148 -11.74%,
    #1b1c1e 120.91%
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: Montserrat, sans-serif;
}
.forgot-password-window input {
  width: 274px;
  height: 26px;
  border-radius: 9px;
  background: #101010;
  /* background: var(
    --Grey-Gradient,
    linear-gradient(180deg, #212121 0%, #101010 100%)
  ); */
  color: rgba(197, 197, 197, 0.7);
  padding-left: 10px;
  border: none;
  margin-top: 1rem;
  margin-bottom: 0.8rem;
}
#main-alert-box {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 98vh;
  right: 0;
  background: rgba(0, 0, 0, 2);
  z-index: 15;
}
.loader {
  position: absolute;
  display: block;
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #fff;
}
.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader:after {
  color: #44d1c8;
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}
.zoho-login-container{
  display: flex;
  height: 6rem;
  flex-direction: row;
  width: 134%;
  justify-content: space-between;
  align-items: center;
}
.signup_container{
  width: 38%;
}
.signup-options{
  color: #ffffff80;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: normal;
  margin-top: 0.5rem;
}


@media screen and (max-width: 1280px) and (max-height: 603px) {
  .main-body {
    background: var(
      --Gradient1,
      linear-gradient(180deg, #212121 0%, #101010 100%)
    );
    width: 100%;
    height: 100vh;
  }
  .login-window {
    width: 70%;
  }
  header {
    padding: 1.25rem;
    margin: 0;
  }
  .login-window {
    width: 750px;
    height: 280px;
  }
  .analytics-logo {
    margin-left: 100px;
    width: 175px;
    height: 79.949px;
  }
  .signup-title {
    font-size: 18px;
    padding: 1rem 3rem 0rem 3rem;
  }
  input {
    width: 320px;
    height: 30px;
  }
  .password-visibility {
    position: absolute;
    top: 36%;
    right: 26%;
    padding-right: 10px;
  }
  ::placeholder {
    font-size: 12px;
  }
  .login-btn {
    width: 100px;
    height: 35px;
    font-size: 12px;
  }
  .signup-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 0.5rem;
  }
  .products-container {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 1.5rem;
  }
  .product p {
    font-size: 10px;
  }
  .product img {
    height: 5.25rem;
  }
  .footer {
    padding-bottom: 1rem;
    padding-top: 0.5rem;
  }
  footer p {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
  }
  .forgot-password-container {
    margin: 2.5rem 2rem;
  }
}
