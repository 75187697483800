.moptro-container {
  width: 100%;
  background: var(--Gradient-2, linear-gradient(180deg, #171717 -12.86%, #3e4148 -12.85%, #1b1c1e 104.45%) );
   border-radius: 10px 0 0 10px;  
}
@media screen and (max-width: 1280px) and (max-height: 603px) {
  .moptro-container {
    height: 82vh;
  }
}
