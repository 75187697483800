@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

.slectantd {
  font-family: "Montserrat", sans-serif;
  text-align: left;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  border: 1px solid #d9d9d9;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: #1b3643;
  border: none;
  color: white;
  font-family: "Montserrat", sans-serif;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.25);
  color: white;
}

.ant-select-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 4px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: absolute;
  top: -9999px;
  z-index: 1050;
  overflow: hidden;
  font-variant: initial;
  background-color: #ffffff;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background: #1b3643;
}
.ant-select-dropdown
  .ant-select-item-option-selected.ant-select-item-option-disabled{
  background-color: #56727f;
  color:#9d9c9c ;
}
.ant-select-dropdown .ant-select-item-option-disabled{
  background-color: #56727f;
  color:#9d9c9c ;
}
.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: white;
  font-weight: 600;
  background-color: #1d98984a;
}
.ant-select-dropdown .ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size:small;
  font-weight: 300;
}

.ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  /* background-color: rgba(29, 152, 152, 0.29); */
   background: var(--Grey-Gradient, linear-gradient(180deg, #666 -12.86%, #5c5c5c -12.85%, #404040 104.45%))
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
:where(
    .css-dev-only-do-not-override-14wwjjs
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  padding-inline-end: 18px;
  font-family: "Montserrat", sans-serif;
  font-size: small;
  font-weight: 300;
}
.ant-select-selector .ant-select-selection-placeholder {
  color: white;
  opacity: 0.8;
}
.userFilter{
  background: var(
    --Gradient-Blue-1,
    linear-gradient(180deg, #71d0f6 0%, #007bc9 100%)
  );
    border-radius: 6px; 
}
.userFilter {
    width: 10rem;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  font-size: 14px;
    font-style: normal;
    font-weight: 400;
}