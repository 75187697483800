@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

.parameter {
  /* position: absolute; */
  /* top: 9%; */
  height: 84%;
  /* left: 18%; */
  width: -webkit-fill-available;
  background: rgb(25 25 25);
  border-radius: 15px;
  gap: 5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
}

.result {
  display: none;
  position: absolute;
  top: 12%;
  height: 84%;
  right: 10%;
  width: 36%;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

#submit {
  height: 2.5rem;
  width: max-content;
  background: linear-gradient(180deg, #00b7a8 0%, #044646 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  opacity: 0.8;
  color: white;
  font-family: "Montserrat", sans-serif;
  z-index: 1;
  font-family: "Montserrat", sans-serif;
  /* margin-top: 3%; */
  border: none;
  margin-left: 4rem;
 
}
#submit:hover {
  opacity: 1;
}
.params {
  /* position: absolute; */
  /* width: inherit; */
  /* top: 2%;
  left: 26%; */
  /* height: 55%; */
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* padding-right: 5.5rem; */
}

.report {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  width: 95%;
  font-size: small;
  row-gap: 1rem;
  text-align: center;
}
.main_body {
  color: white;
  margin-left: 2rem;
    margin-right: 2rem;
}
#main_report {
  /* position: absolute;
  top: 47%; */
  /* width: 95%; */
  /* left: 4%; */
  height: 62vh;
}

.form {
  width: 100%;
  height: 90%;
  /* overflow-y: hidden;
  overflow-x: hidden; */
  background: rgb(0, 0, 0, 0.8);
  padding-top: 2%;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 7%;
  padding-bottom: 2%;
  font-family: "Montserrat", sans-serif;
}

.main_form {
  width: 100%;
  height: 90%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 0%;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 7%;
}

.custom-scrollbars__content {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.custom-scrollbars__content::-webkit-scrollbar {
  display: none;
}

.main_form::-webkit-scrollbar {
  width: 5px;
  opacity: 0.7;
}

.main_form::-webkit-scrollbar-track {
  background: #454545;
  border-radius: 10px;
  opacity: 0.7;
}

.main_form::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #1effed;
  opacity: 0.7;
}
.main_form::-webkit-scrollbar-track:hover {
  opacity: 1;
}
.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #fff;
}
.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader:after {
  color: #ff3d00;
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}
.download_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 2.8rem;
  margin-top: 1rem;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}

#load {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

#download {
  /* position: absolute;
  top: -1rem; */
  right: 2%;
  background: linear-gradient(180deg, #00b7a8 0%, #044646 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: white;
  font-family: "Montserrat", sans-serif;
  /* height: 12%; */
  width: 12%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0.8;
  font-size: 0.75rem;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 5px;
}

#download:hover {
  opacity: 1;
}
.Mop_title {
  background: rgba(0, 0, 0, 0.8);
  width: 17%;
  /* height: 10%; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
  font-family: "Montserrat", sans-serif;
}

#temp_id {
  width: 2rem;
  height: 2rem;
}

a {
  color: white;
  text-decoration: none;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Source+Sans+Pro:wght@300&display=swap");

.vin {
  position: absolute;
  left: 10.88%;
  right: 60.48%;
  top: 14.1%;
  bottom: 56.29%;

  background: rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/* .data-one {
    position: absolute;
    left: 4.93%;
    right: 60.42%;
    top: 45.9%;
    bottom: 10.63%;

    background: rgba(0, 0, 0, 0.22);
    border-radius: 15px;
    font-family: 'Lato', sans-serif;
font-family: 'Source Sans Pro', sans-serif;
} */

.inx2-data-two {
  position: absolute;
  left: 41.3%;
  right: 5.16%;
  top: 14.1%;
  bottom: 49.26%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.22);
  border-radius: 15px;
}

.data-three {
  position: absolute;
  left: 41.3%;
  right: 5.16%;
  top: 52.73%;
  bottom: 10.63%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.22);
  border-radius: 15px;
}

.idx_vehicle-number {
  display: flex;
  align-items: center;
  width: 39%;
  height: 2rem;
  background: #1b3643;
  border-radius: 7px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-;
  margin-left: 2rem;
}
.idx_open-vehicle-number {
  display: flex;
  align-items: center;
  width: 82%;
  height: 2.5rem;
  background: #1b3643;
  border-radius: 15px 15px 0px 0px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
}

.vehicle-date {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  width: 82%;
  height: 2.5rem;
  background: #1b3643;
  border-radius: 15px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
}
.open-vehicle-date {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  width: 82%;
  height: 2.5rem;
  background: #1b3643;
  border-radius: 15px 15px 0px 0px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
}

li {
  list-style: none;
}

.vin-submit {
  background-color: #00b7a8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  width: 40%;
  height: 2.5rem;
  border-radius: 15px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(180deg, #00b7a8 0%, #044646 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.inx2-dropdown-menu {
  position: absolute;
  width: 100%;
  background: #1b3643;
  opacity: 1;
  border-radius: 0px 0px 4px 4px;
  z-index: 15;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  height: 5rem;
  overflow-x: hidden;
  overflow-y: scroll;
  color: white;
  margin: 0;
  border-radius: 0px 0px 15px 15px;
  padding-left: 0;
}

.inx2-dropdown-container {
  width: 100%;
}

.inx2-dropdown-container {
  position: relative;
  width: 100%;
}

.inx2-dropdown-header {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inx2-dropdown-option {
  padding: 10px;
  cursor: pointer;
  padding-left: 0.6rem;
}

.inx2-dropdown-option:hover {
  background-color: rgba(29, 152, 152, 0.29);
}

.inx2-dropdown-option.selected {
  background-color: rgba(29, 152, 152, 0.29);
}

.inx2-dropdown-title {
  padding-right: 10rem;
  display: contents;
}

/* .data-one {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  } */

.vin-submit:hover {
  cursor: pointer;
}

.slider {
  display: flex;
  justify-content: center;
  gap: 50%;
  align-items: center;
  position: absolute;
  top: 90%;
  /* bottom: 12.82%; */
  /* height: 1rem; */
  width: 20%;
  background: rgba(29, 152, 152, 0.29);
  border-radius: 10px;
  height: 1.5rem;
}

.idx-main-logo {
  opacity: 1;
  padding-top: 3px;
}
.idx-main-logo:hover {
  cursor: pointer;
}

.idx-logo {
  opacity: 0.5;
  padding-top: 2px;
}

.idx-logo:hover {
  cursor: pointer;
}
.mop_heading {
  position: absolute;
  top: 12%;
  left: 29%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  z-index: 1;
}

.repo {
  display: flex;
  /* width: 95%; */
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  /* gap: 0.3%; */
  color: white;
  font-weight: 300;
  font-style: normal;
  font-size: medium;
  font-family: "Montserrat", sans-serif;
}

.select_heading {
  /* width: 35%; */
  text-align: left;
  color: white;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Montserrat:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Source+Sans+Pro:wght@300&display=swap");

.vin {
  position: absolute;
  left: 10.88%;
  right: 60.48%;
  top: 14.1%;
  bottom: 56.29%;

  background: rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.data-one {
  position: absolute;
  left: 10.93%;
  right: 60.42%;
  top: 45.9%;
  bottom: 10.63%;

  background: rgba(0, 0, 0, 0.22);
  border-radius: 15px;
  font-family: "Lato", sans-serif;
  font-family: "Source Sans Pro", sans-serif;
}

.data-two {
  position: absolute;
  left: 41.3%;
  right: 5.16%;
  top: 14.1%;
  bottom: 49.26%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.22);
  border-radius: 15px;
}

.data-three {
  position: absolute;
  left: 41.3%;
  right: 5.16%;
  top: 52.73%;
  bottom: 10.63%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(0, 0, 0, 0.22);
  border-radius: 15px;
}

.vehicle-number {
  display: flex;
  align-items: center;
  width: 82%;
  height: 2.5rem;
  background: #1b3643;
  border-radius: 15px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
}
.open-vehicle-number {
  display: flex;
  align-items: center;
  width: 82%;
  height: 2.5rem;
  background: #1b3643;
  border-radius: 15px 15px 0px 0px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
}

.vehicle-date {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  width: 82%;
  height: 2.5rem;
  background: #1b3643;
  border-radius: 15px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
}
.open-vehicle-date {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  width: 82%;
  height: 2.5rem;
  background: #1b3643;
  border-radius: 15px 15px 0px 0px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
}

li {
  list-style: none;
}

.vin-submit {
  background-color: #00b7a8;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  width: 40%;
  height: 2.5rem;
  border-radius: 15px;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
  background: linear-gradient(180deg, #00b7a8 0%, #044646 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.ind-dropdown-menu {
  position: absolute;
  width: 82%;
  left: 9%;
  top: 35%;
  background: #1b3643;
  opacity: 1;
  border-radius: 0px 0px 4px 4px;
  z-index: 15;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  height: 7.5rem;
  overflow-x: hidden;
  overflow-y: scroll;
  color: white;
  margin: 0;
  border-radius: 0px 0px 15px 15px;
  padding-left: 0;
}

.ind-dropdown-container {
  width: 100%;
}

.dropdown-container {
  position: relative;
  width: 200px;
}

.dropdown-header {
  padding-left: 3rem;
  padding-right: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-option {
  padding: 10px;
  cursor: pointer;
  padding-left: 3rem;
}

.dropdown-option:hover {
  background-color: rgba(29, 152, 152, 0.29);
}

.dropdown-option.selected {
  background-color: rgba(29, 152, 152, 0.29);
}

.dropdown-title {
  padding-right: 10rem;
  display: contents;
}

.data-one {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.vin-submit:hover {
  cursor: pointer;
}

.slider {
  display: flex;
  justify-content: center;
  gap: 50%;
  align-items: center;
  position: absolute;
  top: 90%;
  /* bottom: 12.82%; */
  /* height: 1rem; */
  width: 20%;
  background: rgba(29, 152, 152, 0.29);
  border-radius: 10px;
  height: 1.5rem;
}

.idx-main-logo {
  opacity: 1;
  padding-top: 3px;
}
.idx-main-logo:hover {
  cursor: pointer;
}

.idx-logo {
  opacity: 0.5;
  padding-top: 2px;
}

.idx-logo:hover {
  cursor: pointer;
}

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap");

.vd-second-page {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  row-gap: 2rem;
  /* position: absolute; */
  /* top: 3rem; */
  width: 78%;
  /* left: 14%; */
  height: 70%;
}
.vd-value {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  color: #ffffff;
}
.vd-name {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.07em;
  display: flex;
  color: #ffffff;
}

.horizontal-line {
  border: 0.5px solid #5f5f5f;
}
.slider-data {
  position: absolute;
  top: 8%;
  display: flex;
  flex-direction: column;
  left: 12%;
  width: 64%;
}
.slide-details {
  display: flex;
  position: relative;
  width: 100%;
  height: 1.5rem;
  border-radius: 10px;
  background: rgba(48, 48, 48, 0.6);
  margin-top: 1rem;
}

.coloured-part {
  width: 25%;
  background: #2c988f;
  border-radius: 10px 0px 0px 10px;
}

.slider-title {
  margin-left: 23%;
}

.left-indicator {
  position: absolute;
  left: 1rem;
  font-family: "Source Sans Pro", sans-serif;
}

.right-indicator {
  position: absolute;
  right: 1rem;
  font-family: "Source Sans Pro", sans-serif;
}
.good {
  position: absolute;
  top: 2rem;
  left: 1rem;
  font-size: small;
}
.bad {
  position: absolute;
  top: 2rem;
  right: 1rem;
  font-size: small;
}
.to-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  width: 100%;
  flex-direction: column;
}
.first-to-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65%;
  width: 100%;
  flex-direction: column;
  position: absolute;
  top: 27%;
}
.first-map {
  width: 100%;
  padding-top: 1%;
}
.select-parameter {
  position: absolute;
  top: 0;
  width: 5%;
  height: 100%;
  right: 0%;
  background: rgba(0, 0, 0, 0.22);
  border-radius: 0px 15px 15px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
}

#text {
  padding: 15px;
  writing-mode: vertical-lr;
  font-family: "Source Sans Pro", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.07em;

  color: #ffffff;
}
.select-option {
  position: absolute;
  top: 0;
  width: 15%;
  height: 100%;
  right: 0%;
  background: rgba(0, 0, 0, 0.22);
  border-radius: 0px 15px 15px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
}

.parameter-option {
  padding: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
.options {
  display: flex;
  flex-direction: column;
  padding-inline-start: 0.5rem;
  font-size: 14px;
  justify-content: center;
  align-items: center;
}
.parameter-option:hover {
  cursor: pointer;
  background: rgba(29, 152, 152, 0.29);
}

.prm_img_close {
  position: absolute;
  top: 7%;
  left: 2%;
}

.prm_img_close:hover {
  cursor: pointer;
}
.prm_img_open:hover {
  cursor: pointer;
}
.selected-param {
  opacity: 0.5;
  padding: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
.selected-param:hover {
  cursor: defaerror-tablet;
}
.line {
  position: absolute;
  border: 0.5px solid #5f5f5f;
  width: 70%;
  top: 35%;
  left: 9%;
}
.pop-up {
  height: 40%;
  width: 34%;
  background: aqua;
  top: 26%;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  z-index: 100;
  position: fixed;
  border-radius: inherit;
  background: rgba(0, 0, 1, 1);
  left: 35%;
  right: 28%;
  top: 25%;
  /* position: absolute; */
  flex-direction: column;
  gap: 4rem;
  padding-left: 2%;
}
.cross {
  position: absolute;
  top: 5%;
  right: 2%;
}
.cross:hover {
  cursor: pointer;
}

.error-title {
  position: absolute;
  font-family: "Source Sans Pro";
  top: 5%;
  left: 6%;
  font-size: large;
}
.vd-name-new {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.07em;
  display: flex;
  color: #ffffff;
}

.vd-name-new:hover {
  cursor: pointer;
  color: #1effed;
}

.error-table {
  display: grid;
  width: 87%;
  grid-template-columns: 1fr 1fr;
  position: absolute;
  top: 18%;
  gap: 1rem;
  overflow-y: scroll;
  height: 80%;
}
.table-heading {
  font-weight: bold;
}
.table-content {
  color: white;
}
.custom-scrollbars__content {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.custom-scrollbars__content::-webkit-scrollbar {
  display: none;
}

.table-list::-webkit-scrollbar {
  width: 5px;
  opacity: 0.7;
}

.table-list::-webkit-scrollbar-track {
  background: #454545;
  border-radius: 10px;
  opacity: 0.7;
}

.table-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #1effed;
  opacity: 0.7;
}
.table-list::-webkit-scrollbar-track:hover {
  opacity: 1;
}

.table-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  overflow-y: scroll;
}

.table-list-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.custom-scrollbars__content {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.custom-scrollbars__content::-webkit-scrollbar {
  display: none;
}

ul::-webkit-scrollbar {
  width: 5px;
  opacity: 0.7;
}

ul::-webkit-scrollbar-track {
  background: #454545;
  border-radius: 10px;
  opacity: 0.7;
}

ul::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #1effed;
  opacity: 0.7;
}
ul::-webkit-scrollbar-track:hover {
  opacity: 1;
}

.dropdown-title {
  width: 50%;
}

.input_vin {
  background: inherit;
  color: white;
  width: 100%;
}
*:focus {
  outline: none;
}
input {
  border: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 200;
}
.input_vin:hover {
  cursor: pointer;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Montserrat:wght@300&display=swap");

.date-picker-container {
  position: relative;
  width: 100%;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.date-picker-title {
  border: none;
  background: #1b3643;
}

.date-picker-input {
  width: 100%;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-right: 1rem;
}

.date-picker-input input {
  padding-left: 3rem;
  border: none;
  outline: none;
  width: 100%;
  cursor: pointer;
  color: white;
  font-size: inherit;
  font-family: "Montserrat";
}

.date-picker-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow-y: scroll;
}

.date-picker-column div {
  width: 70%;
  padding: 8px 0;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s;
  border-bottom: 2px solid #d3d3d3;
  opacity: 0.5;
}

.date-picker-column div:hover {
  background-color: rgba(29, 152, 152, 0.29);
  opacity: 1;
}

.date-picker-column :hover {
  background-color: rgba(29, 152, 152, 0.29);
  opacity: 1;
}

.date-picker-dropdown {
  position: absolute;
  top: 104%;
  left: 0;
  width: 100%;
  height: 8rem;
  background-color: #1b3643;

  border-radius: 0 0 15px 15px;
  padding: 10px;
  box-sizing: border-box;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.date-picker-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow-y: scroll;
}

.date-picker-column::-webkit-scrollbar {
  display: none;
}

input::placeholder {
  opacity: 1;
  color: white;
  font-family: "Lato", sans-serif;
  font-family: "Montserrat", sans-serif;
}

@media screen and (max-width: 1280px) and (max-height: 603px) {
  .parameter {
    left: 22%;
    height: 80%;
    top: 11%;
    height: max-content;
  }
  .params {
    padding-right: 1.5rem;
  }
  .mop_heading {
    top: 14%;
  }
  #main_report {
    top: 51%;
    /* height: 40%; */
  }
  .idx_vehicle-number{
    width: 50%;
    margin-left: 0;
  }
  /* .select_heading{
    width: 11rem;
  } */
  #submit{
    margin-left: 0;
  }
  .main_body{
    height: inherit;
  }
  .form{
    height: 80%;
  }
}
