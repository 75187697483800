@keyframes verified-checkmark {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

.verified-container {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: transparent;
}

.verified-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid #00ff00; /* Green border */
  box-sizing: border-box;
}

.verified-check {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  transform: translate(-50%, -50%);
  color: #00ff00;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: verified-checkmark 0s ease-out;
}
