@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

.tooltip {
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 1rem;
  color: white;
  background-color: #1b3643;
  font-family: "Montserrat", sans-serif;
  font-size: small;
  border: none;
}
.apexCharts-y-axis-title{
  margin-top: 1rem;
}