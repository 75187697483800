@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

.i-button {
  font-family: Montserrat, sans-serif;
  text-align: center;
  position: relative;
}
.button-card-heading {
  margin: 0;
  font-weight: bold;
}
.i-button-card {
  display: block;
  background-color: #333;
  border-radius: 5px;
  color: #c5c5c5;
  width: 8rem;
  padding: 0.5rem;
  padding-left: 1rem;
  text-align: left;
  position: absolute;
  font-size: 0.65rem;
  left: 6rem;
  top: 1rem;
  border-radius: 5px;
  background: #101010;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;

  &.MOPTro-Up-Time{
    color: #40ff6a;
  }
  &.Average-utilization{
    color: #65d5f3;
  }
  &.Average-Distance-Travelled{
    color:#10eae2
  }
 &.Average-start-stop-cycle{
  color: #e19212;
 }
 &.Average-Speed{
  color:#3AFF75
 }
 &.Average-Vehicle-Usage{
  color: #5C94FF;
 }
 &.Total-Overload-Count{
  color: #DB6B02;
 }
 &.Average-distance-between-2-picks{
  color: #68C9F2;
 }
  
}
.i-button-card p{
  margin-bottom: 0.5rem;
}
.card-content{
  margin-bottom:0.5rem;

  &.In-use{
    color: #40ff6a;
  }
  &.Idle{
    color: #36BCF4;
  }
  &.Disconnected{
    color:#FAC505
  }
  &.In-Service{
    color: #EC46C9;
  }
}

