.login-footer {
  color: #818181;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 1rem 1.25rem 0rem 1.25rem;
}

@media screen and (max-width: 1280px) and (max-height: 603px) {
  .login-footer {
    padding-bottom: 1.1rem;
    padding-top: 0.5rem;
  }
}
