.chart-body {
  background: var(
    --Gradient1,
    linear-gradient(180deg, #212121 0%, #101010 100%)
  );
}
.chart-body-container{
    display: flex;
    height: 85.2vh;
}
