@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

.info-div {
  position: absolute;
  /* background: rgba(0, 0, 0, 0.22); */
  height: 80%;
  top: 12%;
  left: 18%;
  width: 77%;
  border-radius: 15px;
  color: white;
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  flex-direction: column;
  align-items: center;
}
hr{
  background-color: #aca8a8;
  border: none;
  margin-top: 1rem;
  width: 77%;
  height: 0.1rem
}
.info-heading {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  /* background-color: navy; */
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 1rem 0;
  flex-direction: column;
  align-items: center;
}
.info-form {
  width: 64vw;
  height: 48vh;
  background: rgb(0, 0, 0, 0.8);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 0%;
  padding-bottom: 4%;
}

.info-report {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 95%;
  height: 17%;
  align-items: center;
  justify-items: center;
}

.moptro-table {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.heading {
  margin-left: 0.1rem;
}
.password {
  margin-left: 0.2rem;
}

@media screen and (max-width: 1280px) and (max-height: 603px) {
  .info-div {
    width: 75%;
    left: 20%;
  }
}
