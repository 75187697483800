.card {
    margin: auto;
    text-align: center;
    font-family: arial;
    text-align: center;
    font-weight: bold;
    }
    .title {
    font-size: 23px;
    /* color: black; */
    }
    #userimg {
    height: 20%;
    width: 15%;
    }
    div.forgotpwd {
    display: none;
    }
    /* Full-width input fields */
    input[type="text"] {
    width: 90%;
    padding: 15px;
    margin: 15px 0 22px 0;
    display: inline-block;
    border: none;
    border-radius: 8px;
    height: 0.125rem;
    }
    /* Add a background color when the inputs get focus */
    input[type="text"]:focus,
    input[type="password"]:focus {
    /* background-color: #ddd; */
    outline: none;
    }
    input:-internal-autofill-selected {
        /* background-color: white !important; */
        color: black !important;
        box-shadow: 0 0 0px 1000px white inset;
      }
    button:hover {
    opacity: 1;
    }
    .password-container .password-text input{
      margin:0
    }
    /* Float cancel and signup buttons and add an equal width */
    .seperatebtn {
    width: 10%;
    padding: 8px;
    border-radius: 8px;
    }
    .signupbtn {
    width: 10%;
    padding: 8px;
    border-radius: 8px;
    }
    .signupfnbtn {
    width: 20%;
    padding: 8px;
    border-radius: 8px;
    }
    .pwdfnbtn {
    width: 40%;
    padding: 8px;
    border-radius: 8px;
    }
    /* The Modal (background) /
    .modal {
    display: none;
    / Hidden by default /
    position: fixed;
    / Stay in place /
    z-index: 1;
    / Sit on top /
    left: 0;
    top: 0;
    width: 100%;
    / Full width /
    height: 100%;
    / Full height /
    overflow: auto;
    / Enable scroll if needed */
    /* background-color: #474e5d;
    } */
    /* Modal Content/Box /
    .modal-content {
    background-color: #c0c0c0;
    margin: 5% auto 15% auto;
    / 5% from the top, 15% from the bottom and centered /
    width: 25%;
    / Could be more or less, depending on screen size */
    /* } */
    /* The Close Button (x) */
    .close {
    position: absolute;
    right: 35px;
    top: 15px;
    font-size: 40px;
    font-weight: bold;
    color: #f1f1f1;
    }
    .close:hover,
    .close:focus {
    color: #f44336;
    cursor: pointer;
    }
    /* Clear floats */
    .clearfix::after {
    content: "";
    clear: both;
    display: table;
    }
    p.homepage {
    font-size: 23px;
    }
    /* Change styles for cancel button and signup button on extra small screens */
    @media screen and (max-width: 100px) {
    .signupbtn {
    width: 100%;
    }
    }
    .container {
    text-align: center;
    }