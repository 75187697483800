@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");

:root {
  --Green-Gradient: linear-gradient(
    180deg,
    #40ff6a 0%,
    #00ffd1 100%,
    #00ff29 100%
  );
}

/*Dashboard*/
.dashboard-page {
  display: flex;
  justify-content: flex-start;
  height: 84.2vh;
}

.dashboard-body {
  background: var(
    --Gradient1,
    linear-gradient(180deg, #212121 0%, #101010 100%)
  );
}

.dashboard-body-container {
  width: 100%;
  border-radius: 0px 0px 0px 10px;
  background: var(
    --Gradient-2,
    linear-gradient(180deg, #171717 -12.86%, #3e4148 -12.85%, #1b1c1e 104.45%)
  );
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

/*dashboard body*/

.filter-btn-container {
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.filter-btn-container button {
  width: 7rem;
  height: 27px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid var(--Gradient-Blue-1, #71d0f6);
  background-color: transparent;
  color: #c5c5c5;
  font-family: Montserrat, sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.filter-btn-container button.active {
  color: #fff;
  font-family: Montserrat;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px;
  border: none;
  /* border: 1px solid var(--Gradient-Blue-1, #71d0f6); */
  background: var(
    --Gradient-Blue-1,
    linear-gradient(180deg, #71d0f6 0%, #007bc9 100%)
  );
}

.statistics-container {
  display: flex;
  justify-content: center;
}

.live-status-container {
  width: 50%;
  height: 70.3vh;
}

.status-grid-container {
  width: 50%;
}

.live-status-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.live-status {
  width: 260px;
  height: inherit;
  flex-shrink: 0;
  border-radius: 13.5px;
  background: var(
    --Gradient1,
    linear-gradient(180deg, #212121 0%, #101010 100%)
  );
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.live-status.hide,
.status.hide,
.grid1.hide,
.grid2.hide {
  visibility: hidden;
}

.status {
  width: 260px;
  flex-shrink: 0;
  height: inherit;
  border-radius: 13.5px;
  background: var(
    --Gradient1,
    linear-gradient(180deg, #212121 0%, #101010 100%)
  );
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 2rem;
  /* padding-bottom: 2rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* gap: 1rem; */
}

.status-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* column-gap: 2rem; */
  row-gap: 1.5rem;
}

.grid1 {
  position: relative;
  width: 288px;
  height: 270px;
  flex-shrink: 0;
  border-radius: 13.5px;
  background: var(
    --Gradient1,
    linear-gradient(180deg, #212121 0%, #101010 100%)
  );
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.75rem;
}
.grid1 .title img {
  position: absolute;
  top: 0rem;
}
.grid1 .title .i-button-card {
  top: 2rem;
  left: 8rem;
}

.grid2 {
  position: relative;
  width: 288px;
  height: 270px;
  flex-shrink: 0;
  border-radius: 13.5px;
  background: var(
    --Gradient1,
    linear-gradient(180deg, #212121 0%, #101010 100%)
  );
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.grid2 hr {
  width: 222px;
}

/* flip cards */
.flip-card-back {
  color: #333;
  transform: rotateY(180deg); /* Initially hidden by rotating 180 degrees */
  padding: 20px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backface-visibility: hidden;
  font-family: Montserrat, sans-serif;
  /* font-weight: bold; */
  /* width: inherit; */
  /* height: 270px; */
  flex-shrink: 0;
  border-radius: 13.5px;
  background: var(
    --Gradient1,
    linear-gradient(180deg, #212121 0%, #101010 100%)
  );
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
  margin-right: 1rem;
  color: #c5c5c5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.flip-card-back hr {
  /* width: 200px; */
  height: 1px;
  background: #303030;
  border: none;
  /* margin-top: 0.625rem; */
}
.flip-card-grid1.flipped .flip-card-back hr {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
.live-status-container .flip-card-back hr {
  margin: 0.5rem;
}
.live-status-container .utilization .flip-card-back{
    display: flex;
    justify-content: flex-start;
    gap: 7px;
}
.status-grid-container .info .flip-card-back{
  display: flex;
  justify-content: flex-start;
  gap: 6px;
}
 .TwoPick {
  margin-top: 10px;
}
.flip-card-front,
.flip-card-back {
  backface-visibility: hidden;
}
/* .flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
} */
.flip-card-inner {
  transition: transform 0.6s;
  transform-style: preserve-3d;
  height: 98%;
}
.flip-card {
  perspective: 1000px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
.flip-card-grid1,
.flip-card-grid2 {
  perspective: 1000px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card.flipped {
  transform: rotateY(180deg);
}
.flip-card-grid1.flipped {
  transform: rotateY(180deg);
  width: 92%;
}
.flip-card-grid2.flipped {
  margin-right: 1rem;
  transform: rotateY(180deg);
}
.flip-card-grid2.flipped .flip-card-back .info-title {
  height: 4.125rem;
  padding-left: 0;
}
/* .flip-card-grid1.flipped .flip-card-back .info-title {
  padding-left: 1.5rem;
} */
.title .info-button {
  top: 1.5rem;
}
.heading {
  font-size: 1rem;
}
.flip-card-grid1.flipped .flip-card-inner .info-title .info-formula{
  padding: 0;
}
.info-title {
  /* font-family: Montserrat, sans-serif; */
  text-align: left;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* //padding-left: 2rem; */
}
.info-formula {
  font-family: Montserrat, sans-serif;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  padding-bottom: 0.25rem;
  padding-top: 0.375rem;
  display: flex;
  gap: 0.5rem;
}
.backArrow {
  position: absolute;
  top: 1.25rem;
  right: 1rem;
}
.status-grid-container .flip-card-back {
  height: 81%;
  width: 78%;
  left: 2rem;
  right: 1rem;
}

.info-title-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding-bottom: 0.25rem;
  font-family: Montserrat, sans-serif;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  justify-content: space-between;
}
.info-status {
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 8px;
}
.info-status hr {
  margin-top: 0;
}
.info-title .info-status-hr {
  width: 30px;
}
.no-status {
  display: none;
}
.info-unit {
  font-size: 0.938rem;
  font-weight: normal;
}
.overload-info-img {
  width: 1.5rem;
}
.no-img {
  display: none;
}

.grid2 .title img {
  position: absolute;
  top: 0rem;
}
.grid2 .title .i-button-card {
  top: 2rem;
  left: 8rem;
}

.grid3 {
  width: 280px;
  height: 180px;
  flex-shrink: 0;
  border-radius: 13.5px;
  background: var(
    --Gradient1,
    linear-gradient(180deg, #212121 0%, #101010 100%)
  );
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
}

.grid4 {
  width: 280px;
  height: 180px;
  flex-shrink: 0;
  border-radius: 13.5px;
  background: var(
    --Gradient1,
    linear-gradient(180deg, #212121 0%, #101010 100%)
  );
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
}

.heading-container {
  position: relative;
}

.heading-container .icon {
  position: absolute;
}

.heading-container img {
  position: absolute;
  left: 0;
  padding: 10px;
}

.heading-container .icon {
  left: 1.5rem;
  top: 1rem;
}

.grid4 .header-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 2rem 0 2rem;
  gap: 1rem;
  color: #c5c5c5;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150.9%;
  /* 22.635px */
}

.title,
.headings {
  color: #c5c5c5;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 10px;
}
.title .i-button-card {
  display: none;
}
.title:hover .i-button-card {
  display: block;
}

/* .title .i-card {
  font-size: 10px;
  margin-top: 2px;
  margin-bottom: 0;
  display: none;
  position: absolute;
  right: 10%;
  top: 5%;
  width: 7rem;
  height: 3rem;
  text-align: left;
} */
.title img {
  position: absolute;
  right: 8%;
  top: 0rem;
  width: 0.75rem;
  height: 0.75rem;
}
.title img:hover .i-card {
  content: "Your message here";
  position: absolute;
  background-color: #333;
  color: #40ff6a;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  padding-left: 10px;
  /* top: -30px; //Adjust the distance of the tooltip from the button */
  /* left: 50%; */
  /* transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease; */
  /* pointer-events: none;*/
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.json {
  color: #fff;
  font-family: Source Sans Pro;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.json .unit {
  color: #fff;
  font-family: Source Sans Pro;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 10px;
}

.grid2 .vehicle-usage-container {
  margin-bottom: 1rem;
  position: relative;
}
.grid1 .vehicle-usage-container {
  position: relative;
  top: 0.5rem;
}

.json-grid2 {
  color: #fff;
  font-family: Source Sans Pro;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.json-grid2 .data {
  font-family: Source Sans Pro;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: var(
    --Orange-Gradient,
    linear-gradient(180deg, #da6e00 0%, #ed342a 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.avg-speed-container {
  position: relative;
}
.status-grid-container.avg-speed-container .title img {
  top: 1rem;
}
/* .live-status .avg-speed-container .title img {
  top: -1rem;
}
.avg-speed-container .avgDist-img {
  top: -1rem;
} */

.grid2 .avg-speed-container {
  margin-top: 1rem;
}

.uptime .avg-apeed-container {
  margin-top: 1rem;
}

.grid1 hr {
  width: 222px;
  height: 1px;
  background: #303030;
  border: none;
  margin-bottom: 4px;
  margin-top: 8px;
}

.grid2 hr {
  /* width: 200px; */
  height: 1px;
  background: #303030;
  border: none;
}

.grid4 hr {
  width: 200px;
  height: 1px;
  background: #303030;
  border: none;
  padding-left: 2rem;
  margin-top: 0.75rem;
}

.grid-3-status {
  width: -webkit-fill-available;
  height: 76%;
  width: 76%;
  position: relative;
  top: 0.5rem;
}

.grid-3-status-container {
  display: flex;
  justify-content: flex-start;
  margin: 0rem 1rem 3rem 1rem;
  gap: 1rem;
  align-items: baseline;
  margin-top: 2rem;
}

.grid3-graph {
  width: 70px;
  height: 70px;
  position: relative;
}

.grid3-graph img {
  position: absolute;
  top: 4.5rem;
  left: 1.5rem;
}

.grid3-footer {
  color: #c5c5c5;
  text-align: center;
  font-family: Montserrat, sans-serif;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 1.75rem;
  left: 4.5rem;
}

.heading-container hr {
  position: absolute;
  top: 3.5rem;
  left: 1rem;
  right: 1rem;
  /* border-color: #303030; */
  border: none;
  height: 1px;
  background: #303030;
  margin-top: 0.5rem;
}

.utilization hr,
.distance hr,
.live-status hr {
  width: 200px;
  height: 1px;
  background: #303030;
  border: none;
  /* margin-top: 1.5rem; */
}

.utilization {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.utilization .graph {
  position: relative;
  text-align: center;
}

.utilization .utilization-percent {
  /* position: absolute;
  top: 40%;
  left: 7rem; */
  color: #fff;
  font-family: Source Sans Pro;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 5rem;
}

.utilization-percent-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cycle .json-grid2 .data {
  color: #fff;
  font-family: Source Sans Pro;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.uptime .avg-speed-container .data,
.uptime .avg-speed-container .unit {
  flex-shrink: 0;
  font-family: Source Sans Pro;
  font-size: 3.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: var(
    --Green-Gradient,
    linear-gradient(180deg, #40ff6a 0%, #00ffd1 100%, #00ff29 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.live-status .graph {
  position: relative;
}

.graph .live {
  position: absolute;
  top: 0;
  left: 0;
  bottom:200px;
  margin-left: 1.5rem;
}
.graph .title img {
  top: 0rem;
}
.graph .uptime-dummy {
  margin-top: 1rem;
}

.color-box-1 {
  width: 28px;
  height: 18px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(
    --Green-Gradient,
    linear-gradient(180deg, #40ff6a 0%, #00ffd1 100%, #00ff29 100%)
  );
}

.color-box-2 {
  width: 28px;
  height: 18px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(
    --Blue-Green-Gradient,
    linear-gradient(258deg, #00ffdb 13.49%, #4da1ff 89.4%)
  );
}

.color-box-3 {
  width: 28px;
  height: 18px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(
    --Gradient-Red-Pink,
    linear-gradient(180deg, #e440ff 0%, #f55 100%)
  );
}

.color-box-4 {
  width: 28px;
  height: 18px;
  flex-shrink: 0;
  border-radius: 5px;
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, #020024 0%, #f6b608 0%, #ffda00 100%);
}

.status-title {
  color: #c5c5c5;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 6rem;
}

.statistics {
  width: 21px;
  height: 15.804px;
  flex-shrink: 0;
  color: #c5c5c5;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.live-vehicle-status-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: left;
}

.live-vehicle-status {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}
.live-vehicle-status .uptime-img-card {
  top: 0;
  right: 2rem;
}

.warranty {
  color: #c5c5c5;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 3rem;
}

.warranty-name {
  text-align: left;
}

.connected-devices {
  width: 23px;
  height: 15.804px;
  flex-shrink: 0;
  color: #c5c5c5;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.connection-name {
  color: #c5c5c5;
  font-family: Montserrat;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 6.125rem;
  text-align: left;
  padding-left: 10px;
}

.indicator-connected {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(
    --Gradient-Blue-Green,
    linear-gradient(258deg, #00ffdb 13.49%, #4da1ff 89.4%)
  );
}

.indicator-disconnected {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(
    --Grey-Gradient,
    linear-gradient(180deg, #666 -12.86%, #5c5c5c -12.85%, #404040 104.45%)
  );
}

.connectivity {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.data-unavailable {
  font-family: Montserrat;
  color: #40ff6a;
  font-size: 2.5rem;
  font-weight: 600;
}
.data-unavailable img {
  padding-right: 1rem;
  padding-bottom: 0.5rem;
}

.filterUser {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 11%;
  padding-right: 3rem;
  /* background: var(--Gradient1, linear-gradient(180deg, #212121 0%, #101010 100%)); */
  /* margin: 2rem; */
  /* margin-top: 8px; */
  /* margin-bottom: 0.5rem; */
  /* margin-left: 3rem; */
  /* margin-right: 1rem; */
  border-radius: 1rem;
}

.filter-user-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 17.5rem;
  justify-content: flex-end;
}
.filter_user_container_hiddden {
  visibility: hidden;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.filter-user-container p {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  font-family: Montserrat;
  color: #c5c5c5;
}
.userFilter
  .ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(
    --Gradient-Blue-1,
    linear-gradient(180deg, #71d0f6 0%, #007bc9 100%)
  );
}
.filter-icon-container img {
  width: 1.5rem;
}
.filter-icon-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.dashboard-page .ant-layout-sider .ant-layout-sider-dark {
  height: 86vh;
}

@media screen and (max-width: 1280px) and (max-height: 603px) {
  .title img {
    top: 1rem;
  }
  .title .i-button-card {
    top: 2rem;
    left: 4rem;
  }
  /* .dashboard-body-container {
    width: 83vw;
  } */

  .dashboard-page {
    width: 100%;
    height: 82.5vh;
  }

  .live-status-container {
    width: 80%;
    gap: 1.5rem;
  }

  .uptime .avg-apeed-container {
    margin-top: 10px;
  }

  .up-time-unavailable {
    width: 30px;
  }

  .data-unavailable {
    font-size: 1.75rem;
    font-weight: 600;
  }
  .data-unavailable img {
    padding-bottom: 0;
  }

  .json {
    font-size: 1.25rem;
  }

  .grid1 {
    width: 250px;
    height: 13rem;
    gap: 0;
  }
  .grid1 .title .i-button-card {
    top: 2rem;
    left: 6rem;
  }
  .grid2 {
    width: 250px;
    height: 13rem;
  }
  .grid2 .title .i-button-card {
    left: 6rem;
    top: 2rem;
  }

  .grid3 {
    width: 250px;
    height: 10rem;
    position: relative;
  }

  .heading-container hr {
    position: absolute;
    left: 10px;
    right: 10px;
    top: 3.75rem;
    background: #303030;
    border: none;
    width: 222px;
  }

  .grid-3-status-container {
    margin-bottom: 1.8rem;
    margin-top: 3rem;
  }
  .grid-3-status {
    top: -0.25rem;
  }

  .grid3-footer {
    position: absolute;
    top: 1.7rem;
    left: 3.5rem;
  }

  .grid3-graph img {
    position: absolute;
    top: 3.5rem;
    left: 1.5rem;
  }

  .grid4 {
    width: 250px;
    height: 10rem;
  }

  .status-grid-container {
    column-gap: 2rem;
    row-gap: 0.3rem;
  }

  .statistics-container {
    /* width: 80%; */
    padding-right: 1.5rem;
    /* gap: 1.5rem; */
  }

  .live-status {
    width: 14rem;
    height: 25rem;
  }

  .live-vehicle-status {
    padding-bottom: 10px;
  }

  .status {
    width: 14rem;
    height: 23rem;
    justify-content: flex-start;
    padding-bottom: 2rem;
    gap: 1.4rem;
  }

  .filter-btn-container {
    padding-bottom: 1.25rem;
    padding-top: 1.5rem;
  }

  .heading-container .icon {
    left: 1rem;
    top: 1rem;
  }

  .utilization hr,
  .distance hr,
  .live-status hr {
    margin-top: 0.5rem;
  }

  .utilization .graph {
    width: 7.5rem;
    height: 7.5rem;
  }

  .utilization-percent-container {
    top: 53%;
  }

  .connection-name {
    width: 5.625rem;
  }

  .utilization .utilization-percent {
    left: 18%;
    top: 38%;
  }
  /* flip */
  .info-title {
    font-size: 0.625rem;
  }
  .info-formula {
    font-size: 0.813rem;
  }
  .info-title-container {
    padding-bottom: 0;
    font-size: 0.813rem;
  }
  .backArrow {
    top: 0.6rem;
  }
  .flip-card-back {
    padding-top: 0.5rem;
    width: 80%;
    /* padding-bottom:1rem */
  }
  /* .flip-card-grid1.flipped .flip-card-back .info-title{
    padding-left:0rem;
  } */
  .flip-card-grid1.flipped {
    width: 100%;
    height: 100%;
  }
  .flip-card-back hr {
    width: inherit;
  }
  .json-grid2 .data {
    font-size: 1.75rem;
  }
  .filterUser {
    gap: 8%;
    padding-right: 1.5rem;
  }
  .avg-speed-container .title img {
    top: -0.5rem;
  }
  .live-status .avg-speed-container .title img {
    top: 0.5rem;
  }
  .warrenty-status-details {
    padding-top: 0.5rem;
  }
  .warranty {
    padding: 0.25rem 3rem;
  }
  .flip-card-grid2 .avg-speed-container .title img {
    top: 0;
  }
  .flip-card-grid2 .flip-card-back {
    gap: 0.5rem;
  }
  .flip-card-grid2 .flip-card-back hr {
    margin-top: 1rem;
  }
  .status-grid-container .flip-card-back {
    left: 0rem;
    width: 82%;
  }
  .grid4 hr {
    margin-top: 1rem;
  }
  .filter-user-container {
    width: 15rem;
  }
  .grid1 hr{
    margin-top:1rem;
  }
}
