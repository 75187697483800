  /* *{
    background-color: black;
} */
.chartCard {
  /* width: 13 vw; */
  height: 28vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1280px) and (max-height: 603px) {
  .chartCard {
    /* width: 6vw; */
    height: 9.25rem;
    /* padding-bottom: 10px; */
  }
  .chartCard2{
    width: 7.5rem;
    height: 7.5rem;
  }
}
  /* *{
    background-color: black;
} */
.chartCard {
  /* width: 13 vw; */
  height: 28vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1280px) and (max-height: 603px) {
  .chartCard {
    /* width: 6vw; */
    height: 9.25rem;
    /* padding-bottom: 10px; */
  }
  .chartCard2{
    width: 7.5rem;
    height: 7.5rem;
  }
}
