@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap");

.custom-range-picker {
  background-color: #101010;
  height: 2rem;
  width: max-content;
  font-family: "Montserrat", sans-serif;
}

.custom-range-picker .ant-picker-input input::placeholder {
  color: white;
  opacity: 0.8; /* Set placeholder color to white */
}
.custom-range-picker .ant-picker-dropdown {
  background-color: #101010; /* Set popup panel background color to black */
}

.ant-picker .ant-picker-input > input {
  color: white;
  border: none;
  width: 50%;
  font-family: "Montserrat", sans-serif;
  font-size: "medium";
}

.ant-form-item .ant-form-item-label > label {
  color: white;
  font-weight: 300;
  font-style: normal;
  font-size: medium;
  font-family: "Montserrat", sans-serif;
}

.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: #fff;
}

.ant-picker .ant-picker-suffix {
  color: #fff;
}
.time_pick {
  display: grid;
  flex-direction: column;
  grid-template-columns: 1fr;
  color: white;
  /* width: 95%; */
  row-gap: 0.5rem;
  justify-items: end;
  align-items: center;
}

.temp_pick {
  display: grid;
  grid-template-columns: 0.3fr 1fr;
  height: 2rem;
  gap: 1rem;
}
.anticon svg {
  color: #c5c5c5;
  background-color: black;
}
.ant-layout-sider .ant-layout-sider-dark{
  height: 86vh;
}


.ant-form-item {
  margin-bottom: 0;
  height: 2rem;
}
.ant-picker {
  border: none;
  font-family: "Montserrat", sans-serif;
}
.lable {
  font-weight: 300;
  font-style: normal;
  font-size: medium;
  font-family: "Montserrat", sans-serif;
  width: max-content;
}
.lable p {
  font-size: 10px;
  margin-top: 0;
}

.warning {
  color: rgb(255, 85, 85);
}

.ant-radio-wrapper {
  color: white;
  font-family: "Montserrat", sans-serif;
}

.ap_title {
  color: white;
  font-weight: 300;
  font-style: normal;
  font-size: medium;
  font-family: "Montserrat", sans-serif;
}
.ant-picker-dropdown .ant-picker-header {
  display: flex;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.88);
  border-bottom: 1px solid rgba(5, 5, 5, 0.06);
  background-color: #101010;
  color: white;
}
.ant-picker-dropdown .ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
  color: white;
}
.ant-picker-dropdown .ant-picker-panel .ant-picker-footer {
  border-top: 1px solid rgba(5, 5, 5, 0.06);
  /* background-color: #1b3643; */
  background: var(
    --Grey-Gradient,
    linear-gradient(180deg, #666 -12.86%, #5c5c5c -12.85%, #404040 104.45%)
  );
}
.ant-picker-dropdown .ant-picker-date-panel .ant-picker-body {
  padding: 8px 18px;
  background: #101010;
  color: #fff;
}
.ant-picker-dropdown .ant-picker-date-panel .ant-picker-content th {
  width: 36px;
  color: #fff;
}
.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 4px;
  transition: background 0.2s, border 0.2s;
  color: white;
}
.ant-menu{
  font-family: "Montserrat", sans-serif;    
}
.ant-menu-title-content{
padding-left: 1rem;
}
.ant-picker-dropdown .ant-picker-cell-disabled {
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
  opacity: 0.5;
  background-color: rgba(0, 0, 0, 0.25);
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end
  .ant-picker-cell-inner {
  color: #fff;
  background: var(
    --Grey-Gradient,
    linear-gradient(180deg, #666 -12.86%, #5c5c5c -12.85%, #404040 104.45%)
  );
}

.ant-picker-dropdown .ant-picker-year-panel .ant-picker-body,
.ant-picker-dropdown .ant-picker-quarter-panel .ant-picker-body,
.ant-picker-dropdown .ant-picker-month-panel .ant-picker-body {
  padding: 0 8px;
  /* background-color: #1b3643; */
  background: var(
    --Grey-Gradient,
    linear-gradient(180deg, #666 -12.86%, #5c5c5c -12.85%, #404040 104.45%)
  );
}
.ant-picker-dropdown .ant-picker-content th,
.ant-picker-dropdown .ant-picker-content td {
  position: relative;
  min-width: 24px;
  font-weight: normal;
}
.ant-picker-dropdown
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view)
  .ant-picker-cell-inner,
.ant-picker-dropdown
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(
    .ant-picker-cell-range-hover-end
  )
  .ant-picker-cell-inner {
  background: rgb(19, 97, 97);
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: rgb(19, 97, 97);
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  )::before {
  background: rgb(19, 97, 97);
}

.ant-picker-dropdown
  .ant-picker-cell-in-view
  .ant-picker-cell-in-range
  .ant-picker-cell-range-hover::before {
  /* background-color: #1b3643; */
  background: var(
    --Grey-Gradient,
    linear-gradient(180deg, #666 -12.86%, #5c5c5c -12.85%, #404040 104.45%)
  );
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start
  .ant-picker-cell-range-hover::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end.at-picker-cell-range-hover::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ).ant-picker-cell-range-hover-start::before {
  background-color: rgb(19, 97, 97);
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-dropdown-ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
.ant-picker-dropdown.ant-picker-cel1-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-dropdown-ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ).ant-picker-cell-range-hover-start::before,
.ant-picker-dropdown.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ).ant-picker-cell-range-hover-end::before,
.ant-picker-panel›:not(
    .ant-picker-date-panel
  ).ant-picker-dropdown.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel›:not(.ant-picker-date-panel)
  .ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before:hover {
  background-color: rgb(19, 97, 97);
}
.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner > * {
  background-color: #fff;
}

.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
:where(.css-dev-only-do-not-override-14wvjjs)
  .ant-picker-dropdown.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  ::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-dropdownant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
:where(
    .css-dev-only-do-not-override-14wjjs
  ).ant-picker-dropdownant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  ).ant-picker-cell-range-hover-start::before,
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-range-end-single
  ).ant-picker-cell-range-hover-end::before,
.ant-picker-panel›:not(
    .ant-picker-date-panel
  ).ant-picker-dropdownant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel
  > :not(.ant-picker-date-panel)
  .ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background-color: rgb(19, 97, 97);
}

.ant-picker-dropdown
  .ant-picker-panel-container
  .ant-picker-panel
  .ant-picker-content,
.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel table {
  text-align: center;
  background-color: #101010;
}

.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: rgb(19, 97, 97);
}
.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner {
  display: block;
  width: 48px;
  height: 28px;
  margin: 0;
  padding-block: 0;
  padding-inline-end: 0;
  padding-inline-start: 14px;
  color: white;
  line-height: 28px;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.2s;
}
.ant-btn-primary:disabled,
.ant-btn-primary.ant-btn-disabled {
  cursor: not-allowed;
  border-color: #d9d9d9;
  color: white;
  background-color: rgba(0, 0, 0, 0.04);
  box-shadow: none;
}

.ant-picker-dropdown .ant-picker-panel .ant-picker-footer {
  border-top: 1px solid rgba(5, 5, 5, 0.06);
  /* background-color: #1b3643; */
  background: var(
    --Grey-Gradient,
    linear-gradient(180deg, #666 -12.86%, #5c5c5c -12.85%, #404040 104.45%)
  );
}
.ant-select-single {
  width: 18rem;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border: none;
}
.ant-select-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 4px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: absolute;
  top: -9999px;
  z-index: 1050;
  overflow: hidden;
  font-variant: initial;
  background-color: #ffffff;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: #101010;
}
.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  background-color: #101010;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #ffffff;
  border: none;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #101010;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: small;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: white;
}
.ant-select-dropdown .ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: small;
}
.ant-select-dropdown
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: var(
    --Grey-Gradient,
    linear-gradient(180deg, #666 -12.86%, #5c5c5c -12.85%, #404040 104.45%)
  );
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
:where(
    .css-dev-only-do-not-override-txh9fw
  ).ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  padding-inline-end: 18px;
  font-weight: 300;
}
@media screen and (max-width: 1280px) and (max-height: 603px){
  .ant-select-single{
    width: 15.5rem;
  }
}